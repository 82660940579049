.hotel-room-row {
	
	display: table;
	width: 100%;
	&__container {
		@include clearfix;
	    border: 10px solid $brand-color-3;
	    margin-right: -5px;
	    
	    &_header {
	    	@include clearfix;
		    text-align: left;
		    text-transform: uppercase;
		    background-color: $brand-color-4;
	        font-weight: 700;

	    	.inclusion, .toggle-row, .offers, .availability, .room-rate  {
			    padding: .3125rem 0 .3125rem .3125rem;
	    	}
	    	.inclusion {
    		    width: 25%;
    		    float: left;
	    	}
	    	.toggle-row {
	    		width: 20%;
	    		float: left;
	    		> button {
	    			float: right;
    			    background: transparent;
   					border: none;
    				padding-right: 1rem;
    				font-weight: 600;
	    		}
	    	}
	    	.offers {
	    		width: 15%;
	    		float: left;
	    	}
	    	.availability, .room-rate {
    			width: 20%;
    			float: left;
	    	} 
	    }

		&_content {
			.room-detail {
				@include clearfix;
				
				&__room-type {
					min-height: 1px;
					width: 25%;
					float: left;
					
					&_room-desc {
						padding-left: .3125rem;
						color: $gray-base;
						
						.remark-icon {
							padding-left: .5rem;
							color: $brand-color-5;
						}
					}
					&_room-basis {
						padding-left: .3125rem;
						text-transform: uppercase;
					}
				}
				&__room-offers {
					min-height: 1px;
					width: 15%;
					float: left;
				}
				&__room-allocation {
					width: 20%;
					float: left;
					&_status {
						padding: 0.3125rem;
					}
					&_cancellation, &_inline-cancellation  {
						color: $brand-color-2;
						padding: 0.3125rem;
						cursor: pointer;
					}
				}
				&__room-rate {
					width: 20%;
					float: left;
					&_currency, &_total-price, &_adhoc-amount, &_adhoc-component, &_package {
						float: left;
						padding: 0.3125rem;
					}
					&_adhoc-component {
						color: $brand-color-2;
					}
				}
				&__events {
					width: 20%;
					float: left;
					&_book, &_change {
						color: $light;
					    text-decoration: none;
					    background-color: $brand-color-1;
					    padding: .3125rem 0;
    					font-size: .625rem;
   					    margin: 0.3rem;					
   					    width: 70%;
   					    font-weight: 700;
 					    cursor: pointer;
    					text-transform: uppercase;
    					
    					&:disabled {
    						cursor: not-allowed;
    					}
					}
				}
			}
		
		}
	    
	    	
	}
}