.point-of-interest-container{
&__auto-suggest-container{
	.autosuggestion__textbox{
		 width: 99%;
	     margin-left: -1%;
		}
	 .itemcontainer{
	    left: -1px;
	    right: 2px;
		}
	}
}


.recommended-filter__options{
	width: 100%;
    padding: 0.2rem 0.5rem;
    background: 0 0;
}
    