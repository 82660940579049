.hotel__map_view {
	.sidebar-content {
		float: left;
		width: 20%;
		max-height: 37.5rem;
		overflow-y: auto;
		padding: 0.45rem;
	}
	
	.list-content {
		float: left;
		width: 20%;
		max-height: 37.5rem;
		overflow-y: auto;
		padding: 0.45rem;
	}
	
	.map-content {
		float: left;
		width: 60%;
	}
	
	.map-content-wrapper {
		position: relative;
	}
	
	.hotel-map-view-map-wrapper {
		height: 600px;
		width: 100%;
	}
	
	.map-search {
		padding: 5px 0px 5px 10px;
		margin-top: 1rem;
		margin-left: 1rem;
		font-size: 1rem;
		height: 2rem;
		width: 20rem;
	}
	
	.search {
		&__result {
			&_row {
				cursor: pointer;
			}
		}
	}
	
	.map-view {
		&__description {
			@include make-one-full;
			padding-left: 0;
			&_rating {
				float: left;
				.glyphicon {
					color: $star-rating;
				}
			}
			&_amount {
				float: right;
			}
		}
		&__amount {
			&_label {
				text-transform: none;
			}
		}
	}
}

.pac-container {
	z-index: 1100;
}

.pac-item {
	cursor: pointer;
}