@mixin create-ssr-styles_private {
  &-ssr {
    &__wrapper {
      @include clearfix;
      width: 100%;
    }
    &__button {
      @include clearfix;
      >button {
        background-color: $light;
        border: 5px solid $brand-color-3;
        width: auto;
        padding: 0.3rem;
        text-align: left;
        margin-bottom: 1rem;
      }
    }
    &__list {
      padding: 0.3rem;
      width: 100%;
      @include clearfix;
      &-options {
        @include make-one-third;
        @include clearfix;

        //height:2.5rem;
        padding: 0;
        &_label {
          margin: 0;
          width: 93%;
          float: left;
          display: inline;
        }
        >input {
          width: 7%;
          float: left;
        }
      }
    }
    &__free-text {
      padding: 0.3rem;
      &_label {
        width: 100%;
      }
      >textarea {
        resize: none;
      }
    }
    &__note {
      padding: 0.3rem;
      color: $brand-color-1;
      float: left;
      margin-bottom: 0.5rem;
    }
  }
}

@mixin  create-ssr-styles { 
	@include create-ssr-styles_private; 
}