

.hotel-contact__header,.hotel-desc__header,.hotel-amenity__header, .hotel-cancellation-policy__header,.hotel-amendment-policy__header {
	min-height:30px;
	background-color: $hotel-modal-header-container-bg-color;
	padding: 0.375rem;
	color: $light;
	text-transform: uppercase;
}
.hotel-contact__content{
	ul{
		padding-top:10px;
		>li{
			padding-top:3px;
			border-bottom: 2px solid $hotel-contact-content-bottom-border-color;
	
		&:last-child{
		padding-top:3px;	
			border-bottom: none;
			}
		}
	}

}

.hotel-contact__content,.hotel-desc__content,.hotel-amenity__content,.hotel-amendment-policy__content{
	padding-left: 12px;
	padding-bottom: 5px;
	&_hotel-name{
	color: $hotel-contact-content-title-text-color;
			font-size: 12px;
	
	}
	&_title{
			color: $hotel-contact-content-title-text-color;
			font-size: 12px;
			text-transform: uppercase;
	}

	&_info{
	color:$hotel-contact-content-info-text-color;
	padding-bottom: 2px;
	}
	&_city{
		color:$hotel-contact-content-info-text-color;
	}

}
.hotel-desc__content,.hotel-amendment-policy__content{

padding-top:10px;

}
.hotel-amenity{
	&__content{
		@include clearfix;
		padding-left: 0.75rem;
		padding-bottom: 0.5625rem;
		padding-top: 0.5625rem;
		&_amenity{
			min-width: 25%;
			float: left;
		}
	}	
}
.amenity__logo{
padding-left: 15px;
padding-right: 5px;
padding-top: 5px;
padding-bottom: 5px;
}

.amenity__info{

padding-right: 5px;
padding-top: 5px;
padding-bottom: 5px;
}

.hotel-desc__title
{
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    color: #087dc2;
}


