.room-extra-container{
	&__header{
		&_extra-detail-label{
			float: left;
		}
	}
	&__extra-details{
		&_extra-content,.extra-content{
			&__header{
				@include clearfix;
				color: $brand-color-2;
				margin-bottom: .25rem;
				width: 100%;
				border-top: 1px solid $brand-color-2;
			}
			&__room-extra-details{
				margin-left: .25rem;
				&_room-extra-content,.room-extra-content {
					@include clearfix;
					border-top: 1px solid $brand-color-5;
					&__extra-title{
						float: left;
						.checkbox-component{
							color: $brand-color-2;
						}
						.icon{
							width: 1rem;
    						font-size: 1rem;
    						color: $brand-color-31;
    						padding-top: 0.125rem;
						}	
					}
					&__extra-price{
						float: right;
						&_amount{
							color: $brand-color-1;
							font-family: $font-family-number;
						}
					}
				}
			}
		}
	}
}