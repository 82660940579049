@use "sass:math";

@mixin draw-triangle_private($triangle, $size,$color) {
height: 0;
width: 0;
border-color:white;
// border-style: solid;
border: $size solid transparent;
@if $triangle=='top' {
  border-bottom: $size solid $color;
}
@else if $triangle=='bottom' {
  border-top: $size solid $color;
}
@else if $triangle=='left' {
  border-right: $size solid $color;
}
@else if $triangle=='right' {
  border-left: $size solid $color;
}
}
@mixin  draw-triangle($triangle, $size,$color) { 
	@include draw-triangle_private($triangle, $size,$color); 
}

.sorter-header {
  margin-bottom: $base-margin*0.5;
  &__row {
    background-color: $grid-header-bgcolor;
    padding-top: math.div($base-padding, 3);
    padding-bottom: math.div($base-padding, 3);
    @include clearfix;
    > li {
      @include make-one-fifth;
      color: $grid-header-text-color;
      text-align: center;
      border-right: 2px solid $grid-header-border-color;
      line-height: 1.3rem;
      text-transform: uppercase;
      cursor: pointer;
    }
}



.triangle__container {
    border-radius: 100%;
    width: 18px;
    height: 18px;
    //background-color: black;
    position: relative;
    float: right;
    //overflow:auto;
  }
  .triangle_down {
    @include draw-triangle(bottom, 5px,$gray-lighting);
    position: absolute;
    margin-left: 0px;
    top: 10px;
    left: 4px;
     right:0;

  }
.triangle_up{
    @include draw-triangle(top, 5px,$gray-lighting);
    position: absolute;
    bottom: 10px;
    left: 4px;
     right:0;

  }
  .active_triangle_down{
   @include draw-triangle(top, 5px,$brand-color-2);
    position: absolute;
    bottom: 10px;
    left: 4px;
     right:0;
  }
  .active_triangle_up{
   @include draw-triangle(bottom, 5px,$brand-color-2);
    position: absolute;
    top: 10px;
    left: 4px;
     right:0;
  }
}

