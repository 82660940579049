@use "sass:math";

.gm-style .gm-style-iw {
  overflow: visible !important;
  font-weight: 400 !important;
  padding-left: 0px;
}
.gm-style-iw > div {
  overflow: visible !important;
  line-height: 1.35;

  // white-space: nowrap;
}
.hotel__infowindow,
.air__infowindow {
  &_content {
    @include make-row;
    padding-bottom: $base-padding*0.5;
    background-color: $light;
    text-transform: capitalize;
    width: 100%;
    margin-left: 0;
  }
  &_detail {
    float: left;
    width: 100%;
    padding-left: $base-padding*0.5;
    padding-right: math.div($base-padding, 6);
  }
  &_price {
    float: left;
    width: 100%;
    padding-right: $base-padding*0.5;
    padding-left: math.div($base-padding, 6);
    padding-top: $base-padding*0.5;
    text-align: right;
  }
  &_footer {
    &_links {
      @include make-row;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      > li {
        float: left;
        width:33.3333333333%;
        a {
          display: block;
          padding: math.div($base-padding, 6);
          text-align: center;
          cursor: pointer;
          text-transform: uppercase;
          font-size: $font-size-h6;
          font-weight: 500;
        }
      }
    }
  }
  .room-details {
    padding-left: 0;
    padding-right: 0;
    &__content {
    width: 300px;
      background-color: $brand-color-4;
      border-left: 10px solid $brand-color-3;
      border-right: 10px solid $brand-color-3;
      border-top: 10px solid $brand-color-3;
      border-bottom: 10px solid $brand-color-3;
    }
  }
  .room-table {
    table-layout: auto;
    &__wrapper {
      max-height: 120px;
      overflow-y: auto;
      overflow-x: hidden;
    }
    td {
      vertical-align: bottom;
      padding-right: math.div($base-padding, 6);
      padding-bottom: math.div($base-padding, 3);
    }
    td:last-child {
      text-align: right;
    }
    td .result__book_button {
      width: 100%;
      margin-left: 0;
      padding-top: $base-padding*0.1;
      padding-bottom: $base-padding*0.1;
    }
    &__price {
      padding-bottom: math.div($base-padding, 3);
      max-height: 100px;
    }
    &__currency {
      float: none;
    }
    &__amount {
      font-size: $font-size-h3;
      font-family: $font-family-number;
      color: lighten($brand-color-1, 20);
    }
  }
  .infowindow__close {
    display: none;
  }
}
.infowindow {
  &__hotel {
    &_name {
      color: $brand-color-2;
      font-size: 1rem;
      font-weight: 400;
    }
  }
  &__roomdesc {
    &_text {
      color: $brand-color-5;
      font-size: 0.7rem;
    }
  }
  &__rating {
	float: left;
	width: 50%;
	text-align: center;
	padding-top: $base-padding*0.5;
    .glyphicon {
      color: $star-rating;
    }
  }
  &__price {
	float: left;
	width: 50%;
	text-align: center;
    max-height: 100px;
    &_amount {
      font-size: $font-size-h3;
      font-family: $font-family-number;
      color: lighten($brand-color-1, 20);
    }
  }
  &__cancellation {
    &_text {
      font-size: 0.7rem;
    }
  }
  &__book,
  &__select {
    white-space: nowrap;
    font-size: $font-size-h6;
    &_button,
    &_button-active {
      // @include make-button;
      padding-top: math.div($base-padding, 6);
      padding-bottom: math.div($base-padding, 6);
      background-color: $row-book-button-bgcolor;
      border: none;
      line-height: inherit;
      border-radius: 0;
      background-image: none;
      width: 100%;
      color: $light;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  &__select {
    &_button {
      background-color: $brand-color-2;
      &:hover,
      &:focus,
      &:active {
        background-color: $brand-color-2;
      }
    }
    &_button-active {
      color: $brand-color-2;
      background-color: $brand-color-3;
      &:hover,
      &:focus,
      &:active {
        background-color: $brand-color-3;
      }
    }
  }
	&__slideshow,
	&__hotel-highlights {
		background-color: $brand-color-4;
		color: $brand-color-2;
	}
	&__view-rooms {
		background-color: $brand-color-2;
		color: $light;
	}
}
.custom-info {
  max-width: 300px;
  position: absolute;
  top: 5%;
  right: 5%;
  box-shadow: rgba(0, 0, 0, 0.298039) 0 1px 4px -1px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  .hotel__infowindow {
    &_content {
      margin-left: 0;
      margin-right: 0;
      padding-top: $base-padding*0.5;
    }
    &_footer_links {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .air__infowindow {
    &_content {
      margin-left: 0;
      margin-right: 0;
      padding-top: 0px;
    }
    &_footer_links {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .infowindow__close {
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    &:hover {
      color: darken($brand-color-5, 20);
    }
  }

  // background-color: red;
  .room-details {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 0;
    .room-table {
      &__wrapper {
        max-height: 265px;
      }
    }
  }
  .triangle,
  .triangle-active {
    margin-left: 0;
  }
}