.room-listing {
	box-shadow: $row-shadow-color;
	margin-bottom: .5rem;
	&__prev-btn, 
	&__next-btn {
    	width: 20px;
   		position: absolute;
	    font-size: 30px;
    	cursor: pointer;
    	z-index: 1;
		margin-top: 0.638rem;
		color: $brand-color-2;
	}
	&__prev-btn {
		float: left;
    	left: 10px;
	}
	&__next-btn {
		float: right;
	    right: 10px;
	}
	&__total-price-container {
		@include clearfix;
		box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.2);
		
		border-radius: 4px;
	    margin-bottom: .5rem;
	    padding: .5rem;
		width: 100%;
		
		&_total-price,
		&_book-button {
			float: left;
			width: 50%;
		}
		
		&_total-price {
			.total-price {
				&__label,
				&__value {
					float: left;
				}
				
				&__label {
					color: $brand-color-2;
					font-size: 1rem;
				}
				&__value {
					margin-top: -.3125rem;
					margin-left: .5rem;
					
					&_amount {
						color: $brand-color-1;
					    font-family: $font-family-number;
					    font-size: 1.25rem;
					    margin-left: .25rem;
					}
					&_amount-with-adhoc {
						@extend %adhoc-button-style;
					}						
				}
			}
		}
		
		&_book-button {
			.room-listing-book-button {
				float: right;
				
				&__button {
				    background-color: $brand-color-1;
				    border-radius: .25rem;
				    color: $light;
					font-size: 1rem;
					font-weight: 700;
				}
			}
		}
	}
	
	&__content {
		padding: .5rem;
		padding-top: 0;
    	float: left;
    	min-height: 65px;
    	height: auto;
    	max-height: 635px;
		&_header {
			@include clearfix;
			color: $brand-color-2;
		    padding: 0.875rem 0.9375rem;
    		margin-bottom: 0.9375rem;
			box-shadow: $row-shadow-color;
			.header {
				&__room-selection {
					font-size: 0.9375rem;
					text-align: center;
				}
				&__price-container {
					float: right;

					&_room-price-wrapper {
						float: left;
					
						.room-price-wrapper {
						    float: left;
						    width: 30%;
						    margin-top: -0.25rem;
						    padding: 0 .5rem;
						    
						    &__currency {
						    	color: $brand-color-5;
						    	font-size: .75rem;
						    }
						    &__amount {
						    	color: $brand-color-1;
						    	font-family: $font-family-number;
						    	font-size: 1.25rem;
						    }
						    &__amount-with-adhoc {
						    	@extend %adhoc-button-style;
						    }
						}
					}
					
					&_expander-button {
						float: right;
						margin-left: .5rem;
					}
				}
			}
		}
		
		&_rooms {
			margin-bottom: 1rem;
			
			.room-detail-container {
				@include clearfix;
				position: relative;
				
				&__supplier-name {
					width: 15%;
				    float: left;
				    &_text {
			    	    padding-top: 0.5rem;
   						color: $brand-color-2;
				    }				
				}
			
				&__room-information,
				&__radio-button,
				&__room-price-container {
					float: left;
				}
				
				&__radio-button {
					padding: .5rem 0;
					width: 5%;
				}
				
				&__room-information {
					@include clearfix;
					width: 50%;
					
					&_room-container {
						padding: .5rem;
						padding-bottom: 0;
						
						.room-container {
							&__room-name {
								&_room-name-value {
									color: $gray;
									font-size: 1rem;
								}
							}
							&__supplier-name {
								color: $gray;
								font-size: 0.7rem;
							}
						}
					}
					
				}
				
				&__room-status-container {
					float: left;
					width: 10%;
					
					&_room-availability {
						margin-top: .5rem;
						text-align: center; 
						.room-availability {
							float: right;
						}
					}
					&_refundable {
						position: absolute;
    					bottom: 0;
					}
				}
				
				&__room-price-container {
					float: left;
					width: 20%;
					min-height: 90px;
					
					&_price-wrapper {
						float: right;
						
						.price-wrapper {
							&__special-offer-price-container {
								&_offer-label {
									background-color: $brand-color-2;
								    border-radius: 4px;
								    color: $light;
									cursor: pointer;
								    padding: 0 .25rem;
								}
								
								&_amount-wrapper {
									text-decoration: line-through;
									
									.amount-wrapper {
										&__value {
											color: $brand-color-1;
											font-family: $font-family-number;
											font-size: 1rem;
										}
									}
								}
								
								&_price-change-after-offer {
									float : right;
								}
							}
							
							&__total-room-price-container {
								float: right;
								
								&_amount {
									color: $brand-color-1;
									font-family: $font-family-number;
									font-size: 1.25rem;
								}
								&_amount-with-adhoc {
									@extend %adhoc-button-style;						
								}
								&_adhoc{
								    float: right;
									padding: 0.5rem 0 0 0.5rem;
									.adhoc-markup__icon{
										width: 1rem;
										color: $brand-color-2;
									}
								}
							}
						}
					}
					
					&_cancellation-link-without-offer {
						position: absolute;
					    bottom: 0;
					    right: 0;

						.cancellation-link-container {
							float: right;
						
							&__cancellation-button {
								color: $brand-color-2;
								cursor: pointer;
								float: left;
								margin-left: .5rem;
							}
						}
						.cancellation-link-container-disabled {
							pointer-events: none;
							opacity: 0.3;
						}
					}
					
					&_cancellation-link {
						float: left;
						margin-top: .25rem;
						width: 100%;
						position: absolute;
						bottom: 0;
					    right: 0;
						
						.cancellation-link-container {
							float: right;
							
							&__cancellation-button {
								color: $brand-color-2;
								cursor: pointer;
								float: left;
								margin-left: .5rem;
							}
						}
						.cancellation-link-container-disabled {
							pointer-events: none;
							opacity: 0.3;
						}
					}
					
					&_day-wise-rate {
						position: absolute;
					    bottom: 1.25rem;
					    right:0;

						.day-wise-rate-container {
						
							&__day-wise-rate-button {
								color: $brand-color-2;
								cursor: pointer;
								float: left;
							}
						}
					}
					&_day-wise-rate-without-offer {
						position: absolute;
					    bottom: 1.25rem;
					    right:0;

						.day-wise-rate-container {
						
							&__day-wise-rate-button {
								color: $brand-color-2;
								cursor: pointer;
								float: left;
							}
						}
					}
					
				}
				
			}
			
			.room-detail-container-disabled {
				pointer-events: none;
				opacity: 0.3;
			}
			
			.room-separator {
				border-bottom: 1px solid $gray-lighting;
			}
			
			.cancellation-details-container {
				border: 1px solid $gray-lighting;
			    border-radius: 4px;
			    padding: .25rem .5rem;
			    
				&__header {
					@include clearfix;
					color: $brand-color-2;
					margin-bottom: .25rem;
					width: 100%;
					
					&_cancellation-policy-label {
						float: left;
					}
					&_close-button {
						cursor: pointer;
						float: right;
					}
				}
				
				&__combined-rooms-note {
					color: red;
				}
			}
		}
		
		&_rooms:last-child {
			margin-bottom: 0;
		}
	}
	
	&__content:last-child {
		margin-bottom: 0;
	}
	
	%adhoc-button-style {
		color: $brand-color-14;
		font-family: $font-family-number;
		font-size: 1.25rem;		
	}
}






.hotel-room-pane-container {
	overflow-y: auto;
	visibility: hidden;
	max-height: 565px;
    height: auto;
    min-height: 65px;
	&__content{
		visibility: visible;
		height: auto;
	}
	&:hover {
		visibility: visible;
		transition: visibility ease-in-out 0.2s;
	} 
}



