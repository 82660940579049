@use "sass:math";

.cancellation-policy {
	&_room {
		&-header {
			color: $brand-color-2;
			padding: math.div($base-padding, 3);
			border-bottom: 1px solid $gray-lighting;
		}

		&-content {
			padding: math.div($base-padding, 3);
		}
	}
}