.book-card {
    @include travel-card;
    &__table {
        font-family: $font-family-number;
        width: 100%;
                &-flight-dtl {
		 font-weight: 400;
		 color:$dark;
		}
        td, th {
            border-bottom: 1px solid lighten($brand-color-5, 20);
            padding-top: 0.625rem;
            padding-bottom: 0.3125rem;
            //padding-left: 0.625rem;
            //padding-right: 0.625rem;
            font-weight: 600;
             &:last-child {
                text-align: right;
            }
        }
    }
     a {
        color: $brand-color-2;
        text-transform: uppercase;
    }
    &__price {
        margin-bottom: $base-margin*0.5;
        border-bottom: 1px solid lighten($brand-color-5, 20);
        &-text {
            font-size: $font-size-h5;
        }
        &-amount {
            font-family: $font-family-number;
            font-size: $font-size-h3;
            color: $brand-color-1;
        }
    }
    &__button {
        padding-top: 0.3125rem;
        padding-bottom: 0.3125rem;
        background-color: $brand-color-1;
        border: none;
        line-height: inherit;
        border-radius: 0;
        background-image: none;
        width: 100%;
        color: $light;
        font-weight: 700;
        &:hover {
            background-color: darken($brand-color-1, 20);
        }
    }
    .terms {
        margin-top: $base-margin*0.5;
        font-size: $font-size-h6;
    }
}