.room-detail{
	&__content {
			margin-bottom: 1rem;
			padding: 0.50rem;
			padding-bottom: 0.1rem;
			.room-detail-container {
				@include clearfix;
				position: relative;
			
				&__room-information,
				&__radio-button,
				&__room-price-container {
					float: left;
				}
				
				&__radio-button {
					padding: .5rem 0;
					width: 5%;
				}
				
				&__room-information {
					@include clearfix;
					width: 40%;
					
					&_room-container {
						padding: .5rem;
						padding-bottom: 0;
						
						.room-container {
							&__room-name {
								display: flex;
								&_room-name-value {
									color: $gray;
									font-size: $font-size-h4;
									text-transform: uppercase;
								}
								&_binding-rate-applicable-flag {
									font-size: $font-size-number-small;
									color: $brand-color-2;
								}
							}
							&__supplement-info {
								color: $brand-color-2;
								cursor: pointer;
								margin-bottom: 0.125rem;
							}
							&__meal-plan{
								display: flex;
								flex-direction: column;
							}
						}
					}
					
				}
				
				&__room-status-container {
					float: left;
					width: 15%;
					
					&_room-availability, &_room-availability-highlight {
						margin-top: .5rem;
						text-align: center; 
						line-height: 1;
						.room-availability, .room-availability-highlight {
							float: right;
						}
					}
					&_refundable {
						position: absolute;
    					bottom: 0;
					}
				}
				
				&__room-price-container {
					float: left;
					width: 40%;
					
					&_price-wrapper {
						float: right;
						width: 100%;
						display: flex;
						flex-direction: row-reverse;
						align-items: center;
						.price-wrapper {
							&__special-offer-price-container {
								&_offer-label {
									background-color: $brand-color-2;
								    border-radius: 4px;
								    color: $light;
									cursor: pointer;
								    padding: 0 .25rem;
								}
								
								&_amount-wrapper {
									text-decoration: line-through;
									
									.amount-wrapper {
										&__value {
											color: $brand-color-1;
											font-family: $font-family-number;
											font-size: 1rem;
										}
									}
								}
								
								&_price-change-after-offer {
									float : right;
								}
							}
							
							&__total-room-price-container {
								float: right;
								
								&_amount {
									color: $brand-color-1;
									font-family: $font-family-number;
									font-size: 1rem;
								}
								&_amount-with-adhoc {
									@extend %adhoc-button-style;						
								}
								&_adhoc{
								    float: right;
									padding: 0.3rem;
									.adhoc-markup__icon{
										width: 1rem;
										color: $brand-color-2;
									}
								}
								&_local-charge {
									float: right;
									padding: 0.3rem;
									.icon{
										width: 1rem;
										font-size: 1rem;
										color : $brand-color-31;
										padding-top: 0.125rem;
									}
								}
							}
						}
						.offer-price{
 							float: right;
    						margin-top: .25rem;
							float: right;
							&__title{
 								color: $brand-color-2;
 								text-transform: capitalize;
 							}
 							&__amount{
 								color: $brand-color-1;
 							}
 						}
					}
					
					&_cancellation-link-without-offer {
						position: absolute;
					    bottom: 0;
					    right: 0;

						.cancellation-link-container{
							float: right;
						
							&__cancellation-button{
								color: $brand-color-2;
								cursor: pointer;
								float: left;
							}
						}
					}
					
					&_cancellation-link,
					&_amendment-policy-link,&_fare-breakup,&_day-wise-rate,&_extra-link {
						float: left;
						margin-top: .25rem;
						width: 100%;
						
						.cancellation-link-container,.fare-breakup-container,
						.day-wise-rate-container, 
						.amendment-policy-link-container,.extra-link-container {
							float: right;
							
							&__cancellation-button,
							&__amendment-policy-button ,&__fare-breakup-button,&__day-wise-rate-button,&__extras-button{
								color: $brand-color-1;
								cursor: pointer;
								float: right;
								text-align: right;
								&:hover {
          							text-decoration: underline;
        						}
							}
						}
					}
					&_tentative-amount{
						text-transform: none;
						float: right;
						text-align: right;
						color: $brand-color-28;
						margin-top: .25rem;
						width: 100%;
					}
					
					
				}
				&__room-price-container-link{
					display: flex;
					content: "";
					float: right;
					width: 100%;
					align-items: flex-end;
					justify-content: flex-end;
				      > div:not(:last-child){
					   > div{
						border-right: 2px solid #838383;
						padding-right: 0.3125rem;
					   }
				     }
					&_cancellation-link,
					&_amendment-policy-link,
					&_fare-breakup,
					&_day-wise-rate,
					&_extra-link {
						float: right;
						margin-top: 0.25rem;
						padding: 5px;
				
						.cancellation-link-container,
						.fare-breakup-container,
						.day-wise-rate-container,
						.amendment-policy-link-container,
						.extra-link-container {
							float: right;
				
							&__cancellation-button,
							&__amendment-policy-button,
							&__fare-breakup-button,
							&__day-wise-rate-button,
							&__extras-button {
								
								color: $brand-color-1;
								cursor: pointer;
								float: right;
								text-align: right;
								text-transform: capitalize;
								padding-right: 0.313rem;
								&:hover {
									text-decoration: underline;
								}
							}
				
						}
				
					}
				}
			}
			
			.room-separator {
				border-bottom: 1px solid $gray-lighting;
			}
			
			.offer-box{
				&__data {
					color: $brand-color-5;
				}
			}
			
			.horizontal-line {
			   border-bottom: 1px solid $gray-lighting;
			}
		}
		
		&__content:last-child {
			margin-bottom: 0;
		}	
}
.cancellation-details-container, 
.supplements-info-container,
.room-extra-container,
.offer-details-container{
	border: 1px solid $gray-lighting;
	border-radius: 4px;
	padding: .25rem .5rem;
	
	&__header,
	.amendment-policy-container__header {
		@include clearfix;
		color: $brand-color-2;
		margin-bottom: .25rem;
		width: 100%;
		&_close-button {
			cursor: pointer;
			float: right;
		}
	}
	
}
.cancellation-details-container {
	&__header,
	.amendment-policy-container__header {
		&_cancellation-policy-label,
		&_amendment-policy-label {
			float: left;
		}
	}
}
.popover {
	max-width: 100%;
}
